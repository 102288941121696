(function($) {$.fn.inviewClass = function(options){var settings = $.extend({className: 'is-inview', secondClassName: 'is-inview-done', visibleOffset: 200, secondClassInterval: 1000 }, options); var isDisabled = false; if(settings.SecondClassName === ''){isDisabled = true; } return this.each(function(){var $this = $(this); function inviewClass(){let currentPos = pageYOffset; if(currentPos > $this.offset().top - window.innerHeight + settings.visibleOffset){if(!$this.hasClass(settings.className)){$this.addClass(settings.className); if(!isDisabled){var thisTimeoutID = setTimeout(function(){$this.addClass(settings.secondClassName); clearTimeout(thisTimeoutID); }, settings.secondClassInterval); } } } } inviewClass(); $(window).on('resize', function(){inviewClass(); }); $(window).on('scroll', function(){inviewClass(); }); }) } })(jQuery);

const cl = [];
cl.body = document.querySelector('body');
cl.header = document.querySelector('.siteheader');
cl.breakPoint = 600;
cl.headerNav = document.querySelector('.siteheader__nav');
cl.footer = document.querySelector('.sitefooter');
cl.anchorTarget = false;

cl.checkScrollbar = function(){
  function setVW(){

    let scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.documentElement.style.setProperty('--scrollbarwidth', `${scrollbarWidth}px`);
  }
  setVW()
  window.addEventListener('resize', setVW);

}
cl.checkScrollbar();

cl.initInviewClass = function(){
  $('.js-inview').inviewClass({
    visibleOffset: 200,
    secondClassInterval: 1000
  });
}
cl.initInviewClass();


cl.sendGA = function(){
  if(!cl.body.classList.contains('is-user-logged-in')){
    console.log('計測を実行、開発中のため計測を停止中');
    //gtag('config', 'G-TSJ0PBSDM1');
  } else {
    //console.log('ログインユーザーは計測しない');
  }
}

cl.lazyload = function(){

  let currentPos = pageYOffset,
      lazyloadThreshold = 200;

  function doLazyload(){
    currentPos = pageYOffset;

    $('.js-lazyload', cl.container).each(function(){

      let $this = $(this);
      if(currentPos > $this.offset().top - window.innerHeight - lazyloadThreshold){

        if(!$this.hasClass('js-lazyload-done')){

          $this.addClass('js-lazyload-done');
          $this.attr('src', $this.data('src'));

        }

      }
    })
  }
  doLazyload();

  $(window).on('resize scroll', function(){
    doLazyload();
  })

}
cl.lazyload();


cl.anchorTo = (targetID, callback) => {

  if(!targetID){
    return;
  }

  let $target = cl.container.querySelector('#' + targetID),
      waitTime = 0,
      thisOffset = -72;

  if(targetID == 'hero'){
    thisOffset = 0;
  }

  if(window.innerWidth < cl.breakPoint){
    thisOffset = -64;
  }

  //ローカルナビのOFFSET
  if(cl.container.querySelector('.c-localnav') !== null ){

    const headLocalNavFixed = document.querySelector('.c-localnavfixed');
    let localNavFixedOffet = 112;

    if(window.innerWidth > cl.breakPoint){
      localNavFixedOffet = 112;
    } else {
      localNavFixedOffet = 64;
    }
    thisOffset = (localNavFixedOffet + headLocalNavFixed.clientHeight) * -1;
  }


  $.Velocity($target, 'scroll', {
    duration: 800,
    easing: "easeInOutCubic",
    offset: thisOffset,
    delay: waitTime,
    complete: ()=> {
      if(callback){
        callback();
      }
      cl.anchorTarget = false;
    }
  })
}


cl.initPageTop = () => {

  if(document.querySelector('.pagetop') == null) {
    return;
  }

  const pageTopElem = document.querySelector('.pagetop'),
        topElement = document.querySelector('.l-main');

  pageTopElem.addEventListener('click', (event)=> {
    event.preventDefault();
    $.Velocity(topElement, 'scroll', {
      duration: 600,
      easing: "easeInOutCubic",
      offset: 0,
      complete: ()=> {
        cl.anchorTarget = false;
      }
    })
  })
}
cl.initPageTop();


cl.anchorTo = (targetID, callback) => {

  if(!targetID){
    return;
  }


  let $target = cl.body.querySelector('#' + targetID),
      waitTime = 0,
      thisOffset = $('.siteheader').height() * -1;

  if(targetID == 'hero'){
    thisOffset = 0;
  }

  if(window.innerWidth < cl.breakPoint){
    //thisOffset = -64;
  }

  $.Velocity($target, 'scroll', {
    duration: 800,
    easing: "easeInOutCubic",
    offset: thisOffset,
    delay: waitTime,
    complete: ()=> {
      if(callback){
        callback();
      }
      cl.anchorTarget = false;
    }
  })
}

cl.initAnchorlink = function(){
  /*
  const anchorLinkArray = cl.body.querySelectorAll('.js-anchor');

  for(let i = 0; i < anchorLinkArray.length; i++) {
    anchorLinkArray[i].addEventListener('click', (event)=> {
      event.preventDefault();

      const targetID = anchorLinkArray[i].href.split('#')[1];

      cl.anchorTo(targetID);
    })
  }*/

  $(document).on('click', '.js-anchor', function(event) {
    event.preventDefault();
    //$(this).addClass('test')
    const targetID = this.href.split('#')[1];
    cl.anchorTo(targetID);
  })

  if(location.hash){
    setTimeout(function(){
      $(window).scrollTop(0)
      const targetAnchor = location.hash.split('#')[1];
      cl.anchorTo(targetAnchor);
    }, 50);
  }
}
window.addEventListener('DOMContentLoaded', ()=> {
  cl.initAnchorlink();
})


cl.initMenu = function(){

  const menuIcon = document.querySelector('.js-menu-icon'),
        menu = document.querySelector('.js-menu-body'),
        menuBody = document.querySelector('.js-menu-body'),
        menuClose = document.querySelector('.js-menu-close'),
        menuOverlay = document.querySelector('.js-menu-overlay'),
        menuContent = document.querySelector('.js-menu-content');

  cl.openMenu = function(){
    cl.body.classList.add('is-menu-open')
    menuBody.style.display = 'block';
    menuContent.style.opacity = 0;
    menuOverlay.style.opacity = 0;

    $.Velocity(menuOverlay, {
      opacity: 1
    }, {
      duration: 400,
      easing: 'easeInOutCubic',
      begin: ()=> {
        setTimeout(()=> {
          $.Velocity(menuContent, {
            opacity: 1
          }, {
            duration: 400,
            easing: 'easeInOutCubic',
          })
        }, 100);
      }
    })
  }

  cl.closeMenu = function(){
    //console.log('close')
    cl.body.classList.remove('is-menu-open')

    $.Velocity(menuContent, {
      opacity: 0
    }, {
      duration: 400,
      easing: 'easeOutQuad',
      begin: ()=> {
        setTimeout(()=> {
          $.Velocity(menuOverlay, {
            opacity: 0
          }, {
            duration: 300,
            easing: 'easeOutQuad',
          });
        }, 100)
      },
      complete: ()=> {
        menuBody.style.display = 'none';
        $('.c-drilldown__child').removeClass('is-visible')
        $('.c-drilldown__parent').removeClass('is-background');
      }
    })

  }

  menuIcon.addEventListener('click', (event)=> {
    event.preventDefault();
    if(!cl.body.classList.contains('is-menu-open')){
      cl.openMenu();
    } else {
      cl.closeMenu();
    }
  })

  menuClose.addEventListener('click', (event)=> {
    event.preventDefault();
    if(!cl.body.classList.contains('is-menu-open')){
      cl.openMenu();
    } else {
      cl.closeMenu();
    }
  })

  menuOverlay.addEventListener('click', ()=> {
    if(!cl.body.classList.contains('is-menu-open')){
      cl.openMenu();
    } else {
      cl.closeMenu();
    }
  })

  const menuLinkArray = menuBody.querySelectorAll('a')
  for(let i = 0; i < menuLinkArray.length; i++){
    menuLinkArray[i].addEventListener('click', ()=> {
      if(menuLinkArray[i].classList.contains('c-drilldown__forward') || menuLinkArray[i].classList.contains('c-drilldown__back')) {
        //return;
      }
      //cl.closeMenu();
      if(menuLinkArray[i].classList.contains('js-anchor') || menuLinkArray[i].classList.contains('js-pagetop')) {
        cl.closeMenu();
      }
    })
  }
}
cl.initMenu();


cl.initFloatNav = () => {
  //console.log('floatnav');

  const floatNavToggle = document.querySelector('.js-floatnav-toggle'),
        floatNavBody = document.querySelector('.js-floatnav'),
        floatNavOverlay = document.querySelector('.js-floatnav-overlay'),
        floatNavContent = document.querySelector('.js-floatnav-content'),
        floatNavClose = document.querySelector('.js-floatnav-close');

  let isAnimating = false;

  const openFloatnav = () => {
    if(!cl.body.classList.contains('is-floatnav-open')) {

      cl.body.classList.add('is-floatnav-open')

      floatNavBody.style.display = 'block';
      floatNavOverlay.style.opacity = 0;

      $.Velocity(floatNavContent, {
        //translateX: [0, window.innerWidth * -1]
        translateY: [0, floatNavContent.clientHeight * -1]
      }, {
        duration: 400,
        easing: 'easeInOutCubic',
        begin: ()=> {
          isAnimating = true;
          $.Velocity(floatNavOverlay, {
            opacity: 1
          }, {
            duration: 400,
            easing: 'easeInOutCubic',
          })
        },
        complete: ()=> {
          isAnimating = false;
        }
      })
    }
  }


  const closeFloatnav = () => {
    if(cl.body.classList.contains('is-floatnav-open')) {
      $.Velocity(floatNavContent, {
        //translateX: [0, window.innerWidth * -1]
        translateY: [floatNavContent.clientHeight * -1, 0]
      }, {
        duration: 400,
        easing: 'easeInOutCubic',
        begin: ()=> {
          isAnimating = true;
          $.Velocity(floatNavOverlay, {
            opacity: 0
          }, {
            duration: 400,
            easing: 'easeInOutCubic',
            complete: ()=> {
              floatNavBody.style.display = 'none';
              cl.body.classList.remove('is-floatnav-open')
              isAnimating = false;
            }
          })
        }
      })
    }
  }

  floatNavToggle.addEventListener('click', (event)=> {
    event.preventDefault();
    if(isAnimating){
      return;
    }
    if(!cl.body.classList.contains('is-floatnav-open')) {
      openFloatnav();
    } else {
      closeFloatnav();
    }
  })

  floatNavClose.addEventListener('click', (event)=> {
    event.preventDefault();
    closeFloatnav();
  })

  floatNavOverlay.addEventListener('click', (event)=> {
    event.preventDefault();
    closeFloatnav();
  })

  $('.js-anchor').on('click', ()=> {
    closeFloatnav();
  })
}

window.addEventListener('DOMContentLoaded', ()=> {
  cl.initFloatNav();
})


cl.initScrollFunc = ()=> {
  const header = document.querySelector('.siteheader');


  let scrolledOffset = 100;
  /*
  if(window.innerWidth > cl.breakPoint){
    scrolledOffset = 150;
  } else {
    scrolledOffset = 100;
  }*/

  let currentPos = window.pageYOffset;

  function scrollFunc() {
    currentPos = window.pageYOffset;

    if(currentPos > scrolledOffset){
      if(!cl.body.classList.contains('is-scrolled')){
        cl.body.classList.add('is-scrolled');
      }
    } else {
      if(cl.body.classList.contains('is-scrolled')){
        cl.body.classList.remove('is-scrolled')
      }
    }
  }
  scrollFunc()

   window.addEventListener('scroll', ()=> {
    scrollFunc()
  })

  window.addEventListener('resize', ()=> {
    if(window.innerWidth > cl.breakPoint){
      scrolledOffset = 150;
    } else {
      scrolledOffset = 100;
    }
  })

}
cl.initScrollFunc();


//cl.heroSlider;
cl.initHeroSlider = function(){

  if(cl.body.querySelector('.heroslider') == null){
    return;
  }

  const heroSliderElem = document.querySelector('.heroslider'),
        heroSliderSlideElem = heroSliderElem.querySelector('.heroslier__item');
  heroSliderElem.prevBtn = heroSliderElem.querySelector('.swiper-button-prev')
  heroSliderElem.nextBtn = heroSliderElem.querySelector('.swiper-button-next')


  const slideSpeed = 700,
        slideInterval = 3000;

  //console.log('heroslider');
  cl.heroSlider = new Swiper(heroSliderElem, {
    loop: true,
    speed: 400,
    spaceBetween: 0,
    slidesPerView: 'auto',
    centeredSlides: true,
    //allowTouchMove: false,
    autoplay: {
      delay: slideInterval,
      disableOnInteraction: false,
    },
    breakpoints: {
      // when window width is >= 768
      769: {
        speed: slideSpeed,
        spaceBetween: -1,
      },
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      prevEl: heroSliderElem.prevBtn,
      nextEl: heroSliderElem.nextBtn,
    },
    on: {
      init: function () {
        //$('.swiper-slide-active').addClass('is-kenburns');
      },
    },
  })


}
window.addEventListener('DOMContentLoaded', ()=> {
  cl.initHeroSlider();
})

//console.log('product')
cl.initSlider = function(){

  //Newin
  const productSliderElems = document.querySelectorAll('.c-productslider');

  for(let i = 0; i < productSliderElems.length; i++) {

    const thisSliderElem = productSliderElems[i].querySelector('.swiper-container');

    thisSliderElem.nextBtn = productSliderElems[i].querySelector('.swiper-button-next');
    thisSliderElem.prevBtn = productSliderElems[i].querySelector('.swiper-button-prev');

    let resizeTimer = false;

    const thisSlider = new Swiper(thisSliderElem, {
      speed: 300,
      //spaceBetween: 14,
      slidesPerView: 'auto',
      navigation: {
        nextEl: thisSliderElem.nextBtn,
        prevEl: thisSliderElem.prevBtn,
      },
      breakpoints: {
        // when window width is >= 640px
        1024: {
          slidesPerView: 4,
          spaceBetween: 36
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 36
        },
        601: {
          slidesPerView: 2,
          spaceBetween: 36
        }
      },
      on: {
        init: ()=> {
          checkProductSliderImgboxHeight()
        },
        resize: ()=> {

          if(resizeTimer !== false){
            clearTimeout(resizeTimer);
          }
          resizeTimer = setTimeout(()=> {
            checkProductSliderImgboxHeight()
          }, 50);
        }
      }
    });

    function checkProductSliderImgboxHeight(){
      const arrowTopLength = ($('.c-productslider__item .c-card__imgbox').eq(0).innerHeight()) / 2;
      thisSliderElem.nextBtn.style.top = arrowTopLength + 'px';
      thisSliderElem.prevBtn.style.top = arrowTopLength + 'px';
    }




  }

  //Feature
  const featureListTarget = $('.c-featureslider .swiper-wrapper');
  if(featureListTarget){
    //swiper init
    const featureSliderElem = document.querySelector('.c-featureslider');

    if(featureSliderElem){
      featureSliderElem.slider = featureSliderElem.querySelector('.c-featureslider__wrapper');
      featureSliderElem.slideLength = featureSliderElem.querySelectorAll('.swiper-slide').length;
      featureSliderElem.nextBtn = featureSliderElem.querySelector('.swiper-button-next')
      featureSliderElem.prevBtn = featureSliderElem.querySelector('.swiper-button-prev')

      //console.log(featureSliderElem.slideLength);

      /*
      スライダーの有効・無効
        スライド1件の時: 無効
        スライド2-3件の時: PCは無効、モバイルは有効
        スライド4件以上の時: 有効
      */
      let isPCSliderDisable = false,
          isMobileSliderDisable = false;

      if(featureSliderElem.slideLength === 1){
      isPCSliderDisable = true,
      isMobileSliderDisable = true;
      } else if (featureSliderElem.slideLength > 1 && featureSliderElem.slideLength < 4){
      isPCSliderDisable = true,
      isMobileSliderDisable = false;
      } else if (featureSliderElem.slideLength >= 4 ){
      isPCSliderDisable = false,
      isMobileSliderDisable = false;
      }
      //watchOverflow: true, スライダーを無効にしてナビゲーションを隠す
      const featureMainSlider = new Swiper(featureSliderElem.slider, {
        watchOverflow: isMobileSliderDisable,
        speed: 300,
        spaceBetween: 16,
        slidesPerView: 1,
        navigation: {
          nextEl: featureSliderElem.nextBtn,
          prevEl: featureSliderElem.prevBtn,
        },
        breakpoints: {
          // when window width is >= 640px
          1025: {
            slidesPerView: 3,
            spaceBetween: 36,
            watchOverflow: isPCSliderDisable,
          },
          601: {
            slidesPerView: 1,
            spaceBetween: 36,
            watchOverflow: isPCSliderDisable,
          }
        },
      });
    }

  }


  //Designers
  const designersSliderElem = document.querySelector('.c-designersslider');

  //swiper init

  if(designersSliderElem){
    designersSliderElem.slider = designersSliderElem.querySelector('.c-designersslider__wrapper');
    designersSliderElem.slideLength = designersSliderElem.querySelectorAll('.swiper-slide').length;
    designersSliderElem.nextBtn = designersSliderElem.querySelector('.swiper-button-next')
    designersSliderElem.prevBtn = designersSliderElem.querySelector('.swiper-button-prev')

    /*
    スライダーの有効・無効
      スライド1件の時: 無効
      スライド2件の時: PCは無効、モバイルは有効
      スライド3件以上の時: 有効
    */
    let isPCSliderDisable = false,
        isMobileSliderDisable = false;

    if(designersSliderElem.slideLength === 1){
    isPCSliderDisable = true,
    isMobileSliderDisable = true;
    } else if (designersSliderElem.slideLength > 1 && designersSliderElem.slideLength < 3){
    isPCSliderDisable = true,
    isMobileSliderDisable = false;
    } else if (designersSliderElem.slideLength >= 3 ){
    isPCSliderDisable = false,
    isMobileSliderDisable = false;
    }
    //watchOverflow: true, スライダーを無効にしてナビゲーションを隠す
    const desingerMainSlider = new Swiper(designersSliderElem.slider, {
      watchOverflow: isMobileSliderDisable,
      speed: 300,
      //spaceBetween: 12,
      slidesPerView: 1,
      navigation: {
        nextEl: designersSliderElem.nextBtn,
        prevEl: designersSliderElem.prevBtn,
      },
      breakpoints: {
        // when window width is >= 640px
        601: {
          slidesPerView: 'auto',
          //spaceBetween: 36,
          watchOverflow: isPCSliderDisable,
        }
      },
    });

  }



  ///detail
  const detaiMainSliderElem = document.querySelector('.c-detailslider');

  if(detaiMainSliderElem){

    //console.log('detail slider')
    detaiMainSliderElem.slider = detaiMainSliderElem.querySelector('.c-detailslider__wrapper');
    detaiMainSliderElem.nextBtn = detaiMainSliderElem.querySelector('.swiper-button-next')
    detaiMainSliderElem.prevBtn = detaiMainSliderElem.querySelector('.swiper-button-prev')
    detaiMainSliderElem.slideElems = detaiMainSliderElem.querySelectorAll('.swiper-slide')

    //console.log(detaiMainSliderElem.slideElems.length);
    //if()
    let isSliderDisabled = false;
    if(detaiMainSliderElem.slideElems.length < 2) {
      isSliderDisabled = true;
    }
    //console.log(isSliderDisabled)

    const detailMainSlider = new Swiper(detaiMainSliderElem.slider, {
      watchOverflow: isSliderDisabled,
      speed: 300,
      //loop: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: detaiMainSliderElem.nextBtn,
        prevEl: detaiMainSliderElem.prevBtn,
      },
    });
  }

  //ticker
  const tickerElem = document.querySelector('.c-ticker');

  if(tickerElem){
    tickerElem.nextBtn = tickerElem.querySelector('.swiper-button-next')
    tickerElem.prevBtn = tickerElem.querySelector('.swiper-button-prev')

    const tickerMainSlider = new Swiper(tickerElem, {
      speed: 600,
      loop: true,
      navigation: {
        nextEl: tickerElem.nextBtn,
        prevEl: tickerElem.prevBtn,
      },
    });
  }


}
window.addEventListener('DOMContentLoaded', ()=> {
  cl.initSlider();
})


cl.initOtherSection = () => {

  const otherElem = document.querySelector('.l-othersection')
  if(!otherElem){
    return;
  }

  otherElem.tabElems = otherElem.querySelectorAll('.l-othersection__tabitem');
  otherElem.contentArea = otherElem.querySelector('.l-othersection__content');
  otherElem.contentElems = otherElem.querySelectorAll('.l-othersection__contentitem');


  for(let i = 0; i < otherElem.tabElems.length; i++){

    otherElem.tabElems[i].addEventListener('click', (event)=> {
      event.preventDefault();
      console.log(i);

      if(otherElem.tabElems[i].classList.contains('is-current')){
        return;
      }

      for(let k = 0; k < otherElem.tabElems.length; k++){
        if(otherElem.tabElems[k].classList.contains('is-current')){
          otherElem.tabElems[k].classList.remove('is-current')
        }
      }
      otherElem.tabElems[i].classList.add('is-current')

      //console.log();
      otherElem.contentArea.style.height = otherElem.contentArea.clientHeight + 'px';
      $('.l-othersection__contentitem').stop().velocity('fadeOut', {
        duration: 240,
        easing: 'easeInOutQuad',
        complete: ()=> {
          $('.l-othersection__contentitem').removeClass('is-current')
          $('.l-othersection__contentitem').eq(i).addClass('is-current')
          $('.l-othersection__contentitem').eq(i).stop().delay(100).velocity('fadeIn', {
            duration: 400,
            easing: 'easeInOutCubic',
            complete: ()=> {
              otherElem.contentArea.style.height = '';
            }
          })
        }
      })
    })
  }
}
window.addEventListener('DOMContentLoaded', ()=> {
  cl.initOtherSection();
});


cl.initFooterToggle = () => {
  //console.log('toggle')
  //フッターのajax取得に対応するため記述を変更
  /*
  const footerToggleElems = document.querySelectorAll('.c-bottomnav__item--toggle');

  for(let i = 0; i < footerToggleElems.length; i++){

    const thisToggleElem = footerToggleElems[i];

    thisToggleElemTitle = thisToggleElem.querySelector('.c-bottomnav__title');
    thisToggleElem.content = thisToggleElem.querySelector('.c-bottomnav__content');


    thisToggleElemTitle.addEventListener('click', ()=> {
      //console.log(i);

      if(!thisToggleElem.classList.contains('is-open')){
        thisToggleElem.classList.add('is-open');
        $(thisToggleElem.content).stop().velocity('slideDown', {
          duration: 200,
          easing: 'easeOutCubic'
        })
      } else {
        thisToggleElem.classList.remove('is-open');
        $(thisToggleElem.content).stop().velocity('slideUp', {
          duration: 200,
          easing: 'easeOutCubic'
        })
      }


    })
  }*/

  $(document).on('click','.c-bottomnav__title', function(event){

    const $this = $(this),
          $thisArea = $this.parent('.c-bottomnav__item--toggle'),
          $thisContent = $this.next('.c-bottomnav__content');

    if(!$thisArea.hasClass('is-open')){
      $thisArea.addClass('is-open');
      $thisContent.stop().velocity('slideDown', {
        duration: 200,
        easing: 'easeOutCubic'
      })
    } else {
      $thisArea.removeClass('is-open');
      $thisContent.stop().velocity('slideUp', {
        duration: 200,
        easing: 'easeOutCubic'
      })
    }



  })

}
window.addEventListener('DOMContentLoaded', ()=> {
  cl.initFooterToggle();
});


cl.initSharePost = function(){


  const $shareBlock = $('.c-share__list'),
        pageTitle = document.title;

  $('a', $shareBlock).each(function(){
    const $this = $(this);

    $this.on('click', function(e){

      e.preventDefault();
      const thisShareMedia = $this.data('sharemedia');
      let pageURL
      switch (thisShareMedia) {
        case 'facebook':
          pageURL = $this.attr('href');
          window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(pageURL), '', 'width=626,height=436');
          break;

        case 'twitter':
          pageURL = $this.attr('href');
          window.open('https://twitter.com/share?text=' + encodeURIComponent(pageTitle) + '&url=' + encodeURIComponent(pageURL), '', 'width=626,height=436');
          break;

        case 'line':
          pageURL = $this.attr('href');
          window.open('http://line.me/R/msg/text/?' + encodeURIComponent(pageTitle) + encodeURIComponent(pageURL) + '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=650,width=450');
          break;
      }
    })
  })
}

window.addEventListener('DOMContentLoaded', ()=> {
  cl.initSharePost();
});

cl.initSearchForm = function(){

  const $searchElem = $('.js-search');
  $searchElem.icon = $('.js-search__icon');
  $searchElem.form = $('.js-search__form');

  $searchElem.icon.on('click', ()=> {
    console.log('js-search')
    if(!$searchElem.hasClass('is-open')){
      $searchElem.addClass('is-open')
      $searchElem.form.stop().velocity('fadeIn', {
        speed: 200,
        easing: 'easeOutCubic',
      })
    } else {
      $searchElem.form.stop().velocity('fadeOut', {
        speed: 200,
        easing: 'easeOutCubic',
        complete: function(){
          $searchElem.removeClass('is-open')
        }
      })
    }
  })
}
window.addEventListener('DOMContentLoaded', ()=> {
  cl.initSearchForm()
});


cl.setPartsLayout = function(){

  $('.c-card').each(function(){
    const $this = $(this);
    //console.log($('.c-card__title img', $this).length);
    if($('.c-card__title img', $this).length > 0){
      const $thisImg = $('.c-card__title img', $this);
      //console.log()
      $this.addClass($thisImg.attr('class'))
    }
  })

  var metalist = document.getElementsByTagName('meta');
  for(var i = 0; i < metalist.length; i++) {
      var name = metalist[i].getAttribute('name');
      if(name && name.toLowerCase() === 'viewport') {
          metalist[i].setAttribute('content', 'width=device-width, initial-scale=1, user-scalable=yes');
          break;
      }
  }

}

window.addEventListener('DOMContentLoaded', ()=> {
  cl.setPartsLayout();
});


